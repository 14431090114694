<template>
  <div class="allTemplate">
    <div class="searchflex">
      <div class="searchLeft">
        <div class="searchInput">
          <el-input
            size="mini"
            v-model="search"
            @input="getSearch()"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
      </div>
      <div class="searchRight">
        <img src="@/assets/image/add_new.png" class="addIcon" @click="add()" />
      </div>
    </div>
    <yh-table
      tableBorder
      :tableHeight="tableHeight"
      :tableLoading="tableLoading"
      :tableHead="tableHead"
      :tableData="tableData"
    >
      <template #tableRow="{ row, item }">
        <span>{{ row[item.prop] }}</span>
      </template>
      <el-table-column label="操作" width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <yh-popover
            popoverTitle="路由配置"
            @savePopUp="deploy(scope.row)"
          ></yh-popover>
          <yh-popover
            popoverTitle="修改"
            @savePopUp="edit(scope.row)"
          ></yh-popover>
          <yh-popover
            popoverOnly="1"
            popoverTitle="删除"
            @savePopUp="del(scope.row)"
          >
            确定删除该信息吗？
          </yh-popover>
        </template>
      </el-table-column>
    </yh-table>
    <div class="pages">
      <el-pagination
        :current-page="page"
        :total="total"
        :page-size="size"
        :page-sizes="[20, 30, 40, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 新增编辑 -->
    <yh-dialog :dialogObj="dialogObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formRule="formRule"
        :formHead="formHead"
        :formData="formData"
      ></yh-form>
      <template #footer>
        <el-button size="mini" type="primary" v-if="prepare" @click="submit()">
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>
    <!-- 路由配置 -->
    <yh-dialog :dialogObj="deployObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formRule="deployRule"
        :formHead="deployHead"
        :formData="deployData"
      ></yh-form>
      <template #footer>
        <el-button
          size="mini"
          type="primary"
          v-if="prepare"
          @click="deploySubmit()"
        >
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      // 表格
      tableLoading: false,
      tableHeight: "100px",
      tableHead: [
        {
          prop: "code",
          label: "编码",
          fixed: false,
          minWidth: "130",
        },
        {
          prop: "name",
          label: "名称",
          fixed: false,
          minWidth: "120",
        },
      ],
      tableData: [],
      page: 1,
      size: 20,
      total: 0,

      // 新增修改
      dialogObj: {
        dialogTitle: "新增",
        dialogOpen: false,
      },
      formRule: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入编码", trigger: "blur" }],
      },
      formHead: [
        {
          comp: "el-input",
          label: "编码",
          prop: "code",
          widthSize: 1,
        },
        {
          comp: "el-input",
          label: "名称",
          prop: "name",
          widthSize: 1,
        },
      ],
      formData: {
        id: undefined,
        code: undefined,
        name: undefined,
      },
      // 路由配置
      deployObj: {
        dialogTitle: "路由配置",
        dialogOpen: false,
      },
      deployRule: {
        routingPrefix: [
          { required: true, message: "请输入路由前缀", trigger: "blur" },
        ],
        frontendUrl: [
          { required: true, message: "请输入前端服务地址", trigger: "blur" },
        ],
        backendUrl: [
          { required: true, message: "请输入后端路由地址", trigger: "blur" },
        ],
      },
      deployHead: [
        {
          comp: "el-input",
          label: "路由前缀",
          prop: "routingPrefix",
          widthSize: 1,
        },
        {
          comp: "el-input",
          label: "前端服务地址",
          prop: "frontendUrl",
          widthSize: 1,
        },
        {
          comp: "el-input",
          label: "后端路由地址",
          prop: "backendUrl",
          widthSize: 1,
        },
      ],
      deployType: "",
      deployData: {
        routingPrefix: undefined,
        frontendUrl: undefined,
        backendUrl: undefined,
      },

      prepare: true,
    };
  },
  created() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 表格
    getSearch() {
      this.page = 1;
      this.getData();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      this.tableLoading = true;
      this.$axios.get(
        "products",
        {
          perPage: this.size,
          page: this.page,
          q: this.search,
        },
        (res) => {
          this.tableLoading = false;
          const status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.tableData = res.data;
            this.total = Number(res.headers["x-total-count"]);
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    // 新增
    add() {
      this.formData = {
        id: undefined,
        code: undefined,
        name: undefined,
      };
      this.dialogObj.dialogOpen = true;
      this.dialogObj.dialogTitle = "新增";
    },
    edit(row) {
      this.formData = row;
      this.dialogObj.dialogOpen = true;
      this.dialogObj.dialogTitle = "修改";
    },
    submit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          this.prepare = false;
          if (this.formData.id) {
            this.$axios.jsonPost(
              "productsEdit",
              {
                id: this.formData.id,
                code: this.formData.code,
                name: this.formData.name,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("修改成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          } else {
            this.$axios.jsonPost(
              "products",
              {
                code: this.formData.code,
                name: this.formData.name,
              },
              (res2) => {
                this.prepare = true;
                var status2 = res2.status;
                if (status2.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("添加成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res2.data.message);
                }
              }
            );
          }
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },
    resetForm() {
      if (this.$refs.formName) {
        this.$refs.formName.resetFields();
      }
    },
    del(row) {
      this.$axios.jsonPost("productsDel", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    deploy(row) {
      this.deployType = row.id;
      this.$axios.get("routeConfig", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          if (res.data && res.data.routingPrefix) {
            this.deployData = res.data;
          } else {
            this.deployData = {
              routingPrefix: "/" + row.code,
              frontendUrl: undefined,
              backendUrl: undefined,
            };
          }
          this.deployObj.dialogOpen = true;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    deploySubmit() {
      this.prepare = false;
      this.$axios.jsonPost(
        "routeConfigEdit",
        {
          id: this.deployType,
          routingPrefix: this.deployData.routingPrefix,
          frontendUrl: this.deployData.frontendUrl,
          backendUrl: this.deployData.backendUrl,
        },
        (res) => {
          this.prepare = true;
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.deployObj.dialogOpen = false;
            this.$message.success("保存成功");
            this.resetForm();
            this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    getHeight() {
      this.tableHeight = window.innerHeight - (50 + 40 + 60 + 30 + 45) + "px";
    },
  },
};
</script>
<style scoped>
.pages {
  text-align: right;
  padding: 5px 0;
  border: 1px solid #ddd;
}
</style>
